import React, { useEffect } from "react"

import { CustomPageProps } from "@utils/types"
import { BaseLayout } from "@layouts/BaseLayout"
import { Container } from "@ui/Container"
import { Row } from "@ui/Row"
import NotFound from "@icons/NotFound.svg"

import * as s from "@styles/NotFound.module.sass"

const NotFoundPage: React.FC<CustomPageProps> = ({ pageContext, location }) => {
  useEffect(() => {
    const body = document.querySelector("body")
    if (body) {
      body.classList.add("gray")
    }
    return () => {
      if (body) {
        body.classList.remove("gray")
      }
    }
  }, [])

  return (
    <BaseLayout
      pageContext={pageContext}
      location={location}
      title="404: Not Found"
      theme="blue"
      className={s.main}
    >
      <Container className={s.root}>
        <Row className={s.row}>
          <NotFound className={s.icon} />
          <h1 className={s.header}>
            <span className={s.light}>404</span> Беда!
          </h1>
        </Row>
      </Container>
    </BaseLayout>
  )
}

export default NotFoundPage
